<template>
  <div>
    <div class="d-flex flex-row">
      <h3>Titel</h3>
    </div>
    <EditDescription :store :createAsTemplate />
  </div>
  <SolutionCriteria />
</template>

<script setup lang="ts">
import { EditDescription, useStore } from '@dims/components';
import SolutionCriteria from './steps/SolutionCriteria.vue';

const { createAsTemplate = false } = defineProps<{
  /** when set to true it will create a template instead of a draft */
  createAsTemplate?: boolean }>();
const store = useStore();

</script>

<style scoped>
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
